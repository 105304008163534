import SentryErrorHandler from 'adaptive-ui/src/js/utils/sentryErrorHandler';

if (__DEV__ === false) {
    SentryErrorHandler.init('https://d38703843621be2fd51efa42f612ae85@o4507418212040704.ingest.de.sentry.io/4507650213150800');
}

import './../jQuery/registerJquery';
import 'jquery-ui/mouse';
import 'jquery-ui-touch-punch';

import './components/Animation';
import './components/Announcement';
import './components/CheckboxToggle';
import './components/CheckboxSelectAll';
import './components/GoTo';
import './components/InstagramFeed';
import './components/Map';
import './components/Navigation';
import './components/Newsletter';
import './components/Spinbox';
import './components/TrucilloMenu';
import './components/TrucilloMap';

// AUI
import 'adaptive-ui/src/js/inits/gallery';
import 'adaptive-ui/src/js/inits/cookies-policy';

// cart
import './cart/CartPreview';
import './cart/CartRecalculator';
import './cart/PromoCode';

// product
import './product/AddToCart';
import './product/VariantSelector';

// order
import './order/OrderTransportAndPayment';
import './order/OrderSummaryBox';
import './order/DpdPlaceSelector';
//import './order/Ekokom';

// user
import './user/Login';
import './user/Registration';

import netteForms from "nette-forms";
import naja from "naja";
import {LoadingIndicator} from "./components/LoadingIndicator";



window.Nette = netteForms;
netteForms.initOnLoad();

naja.registerExtension(new LoadingIndicator());
document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));