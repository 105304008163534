import "slick-carousel";

class InstagramFeed {
    static init() {
        let $instagramFeed = $('.js-instagram-feed');

        if ($instagramFeed.length === 0) {
            return;
        }

        $instagramFeed.slick({
            infinite: true,
            dots: false,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 868,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 548,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    }
}

naja.addEventListener('init', (event) => {
    InstagramFeed.init($('body'));
});
