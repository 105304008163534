export function createLoaderOverlay (loaderElement, loaderMessage) {
    loaderElement = loaderElement || 'body';
    loaderMessage = loaderMessage || 'Načítám';

    const $loaderOverlay = $($.parseHTML(
        '<div class="overlay-inline__in">'
        + '<div class="overlay-inline__spinner">'
        + '<span class="overlay-inline__spinner__icon"><i class="bi bi-spinner"></i></span>'
        + '<span class="overlay-inline__spinner__message">' + loaderMessage + '</span>'
        + '</div>'
        + '</div>'));

   // console.log(loaderElement);

    if (loaderElement !== 'body') {
        $loaderOverlay.addClass('overlay-inline__in--absolute');
        $loaderOverlay.find('.overlay-inline__spinner').addClass('overlay-inline__spinner--absolute');
    }

    $loaderOverlay.data('loaderElement', loaderElement);

    return $loaderOverlay;
}

export function showLoaderOverlay ($loaderOverlay) {
    const $loaderElement = $($loaderOverlay.data('loaderElement'));

    //console.log($loaderOverlay.data('loaderElement'));
   // console.log($loaderElement);

    $loaderElement
        .addClass('overlay-inline')
        .append($loaderOverlay);
}

export function removeLoaderOverlay ($loaderOverlay) {
    const $loaderElement = $($loaderOverlay.data('loaderElement'));

    $loaderOverlay.remove();

    // If multiple overlays are shown over the same element class should be removed only when no overlay is shown anymore
    if ($loaderElement.children('.overlay-inline__in').length === 0) {
        $loaderElement.removeClass('overlay-inline');
    }
}
