import Modal from '../utils/Modal';

class Newsletter {

    constructor ($newsletterForm) {
        $newsletterForm.on('submit', (event) => this.onSubmit(event));
    }

    onSubmit (event) {
        event.preventDefault();

        let $form = $(event.currentTarget);

        naja.makeRequest('POST', $form.attr('action'), $form.serialize())
            .then((response) => {
                new Modal({
                    body: 'Byl(a) jste úspěšně přihlášena k odběru novinek.',
                    buttonCancel: true,
                    buttonContinue: false,
                    textCancel: 'Zavřít'
                });
            });
    };

    static init ($container) {
        $container.find('.js-newsletter-form').each(function () {
            new Newsletter($(this));
        })
    }

}

naja.addEventListener('init', () => {
    Newsletter.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    Newsletter.init($(event.detail.snippet));
});

