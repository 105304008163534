
import SimpleLightbox from 'simplelightbox';
import Helpers from '../helpers';

export default class Gallery {
  static init(galleryElement) {
    const randomIdentifierAttr = 'data-aui-gallery-id';
    const randomIdentifier = Helpers.generateRandomId();

    galleryElement.setAttribute(randomIdentifierAttr, randomIdentifier);

    const defaultOptions = {
      animationSlide: false,
      animationSpeed: 100,
      history: false,
      uniqueImages: false,
      uniqueItems: false,
      fileExt: false,
      overlayOpacity: Number(galleryElement.getAttribute('data-aui-gallery-overlay-opacity')) || 0.7,
    };

    new window.SimpleLightbox(`[${randomIdentifierAttr}="${randomIdentifier}"] a`, defaultOptions);
  }
}