import naja from 'naja';

export default class Ajax {
    static post(url, data, options) {
        return naja.makeRequest('POST', url, data, options);
    }

    static get (url, data, options) {
        return naja.makeRequest('GET', url, data, options);
    }
}
