export default class OrderSummaryBox {

    static loadOrderSummaryBox() {
        let $orderSummaryBox = $('#js-order-summary-box');
        let url = $orderSummaryBox.data('url');

        let $checkedTransport = $('.js-order-transport-input:checked');
        let $checkedPayment = $('.js-order-payment-input:checked');
        let $selectedCountry = $('.js-order-country-input');
        let $companyTaxNumber = $('.js-order-company-tax-number-input');

        let checkedTransportId = $checkedTransport.data('id');
        let checkedPaymentId = $checkedPayment.data('id');
        let selectedCountryId = $selectedCountry ? $selectedCountry.val() : undefined;
        let companyTaxNumber = $companyTaxNumber ? $companyTaxNumber.val() : undefined;

        let data = {};

        if (checkedTransportId !== undefined) {
            data['orderSummaryBox-transportId'] = checkedTransportId;
        }

        if (checkedPaymentId !== undefined) {
            data['orderSummaryBox-paymentId'] = checkedPaymentId;
        }

        if (selectedCountryId !== undefined) {
            data['orderSummaryBox-countryId'] = selectedCountryId;
        }

        if (companyTaxNumber !== undefined) {
            data['orderSummaryBox-companyTaxNumber'] = companyTaxNumber;
        }

       // console.log(data);
       // console.log(url);

        naja.makeRequest('GET', url, data, { history: false });
    }

    static init ($container) {
        $container
            .find('.js-order-transport-input, .js-order-payment-input, .js-order-country-input, .js-order-company-tax-number-input, .js-ekokom-input')
            .change('change', OrderSummaryBox.loadOrderSummaryBox);
    }

}

naja.addEventListener('init', (event) => {
    OrderSummaryBox.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    OrderSummaryBox.init($(event.detail.snippet));
});
