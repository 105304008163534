export default function registerFilterAllNodes () {
    $.extend($.fn, {
        filterAllNodes: function (selector) {
            const $result = $(this).find(selector).addBack(selector);
            $result.prevObject = $result.prevObject.prevObject;

            return $result;
        }
    });
}
