import Modal from '../utils/Modal';
import Registration from "./Registration";

export default class Login {

    static init() {
        $('.js-login-button').each(function () {
            $(this).on('click', Login.onLoginClick);
        });
    };

    static onLoginClick(event) {
        naja.makeRequest(
            'GET',
            $(event.currentTarget).attr('href'),
            null,
            {
                history: false
            }
        ).then(function (response) {
            const modal = new Modal({
                title: 'Přihlášení',
                body: response.content,
                cssClass: 'js-login-modal'
            });

            const $modal = modal.getModal();
            $modal.on('submit', '.js-login-form', Login.onSubmit);

            naja.uiHandler.bindUI($modal.get(0));

            Registration.init();
        });

        event.preventDefault();
    };

    static onSubmit(event) {
        event.preventDefault();

        naja.uiHandler.submitForm(event.target, {
            history: false,
            forceRedirect: true,
            loaderElement: '.js-login-modal'
        });
    };

}

naja.addEventListener('init', () => {
    Login.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    Login.init($(event.detail.snippet));
});