import {createLoaderOverlay, removeLoaderOverlay, showLoaderOverlay} from "../utils/loaderOverlay";

export class LoadingIndicator {

    initialize (naja) {
        naja.uiHandler.addEventListener('interaction', this.locateLoadingIndicator.bind(this));
        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
    }

    locateLoadingIndicator({detail}) {
        let loaderElement = detail.element.dataset.loaderElement;
        let loaderMessage = detail.element.dataset.loaderMessage;

        detail.options.loaderElement = loaderElement;
        detail.options.loaderMessage = loaderMessage;
    }

    showLoader ({detail}) {
        if (this.$loaderOverlay === undefined) {
            this.$loaderOverlay = createLoaderOverlay(detail.options.loaderElement, detail.options.loaderMessage);
        }

        showLoaderOverlay(this.$loaderOverlay);
    }

    hideLoader () {
        if (this.$loaderOverlay !== undefined) {
            removeLoaderOverlay(this.$loaderOverlay);
            this.$loaderOverlay = undefined;
        }
    }
}
