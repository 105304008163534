import Cookies from 'js-cookie';

class Announcement {

    constructor () {
        $('.js-announcement').each(function () {
            let hash = $(this).data('hash');
            let isHidden = Cookies.get('hidden-announcement-' + hash);

            if (isHidden === undefined) {
                $(this).show();
            }
        });

        $('.js-announcement-close').on('click', function (event) {
            let $announcement = $(this).closest('.js-announcement');
            let hash = $announcement.data('hash');

            if (hash === undefined) {
                return;
            }

            Cookies.set('hidden-announcement-' + hash, true, { path: '/' });

            $announcement.remove();

            event.preventDefault();
        });
    }

    static init () {
        new Announcement();
    }

}

naja.addEventListener('init', (event) => {
    Announcement.init($('body'));
});
