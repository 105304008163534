window.initMap = function () {
    var div = document.getElementById('map');

    if (div) {
        var position = {lat: 50.769145, lng: 15.058489};
        var map = new google.maps.Map(
            div, {zoom: 17, center: position});

        new google.maps.Marker({position: position, map: map});
    }
};
