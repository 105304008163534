import naja from 'naja';
import Gallery from '../classes/gallery';

export default function initGallery(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-gallery]')?.forEach((galleryElement) => {
    Gallery.init(galleryElement);
  });
}

naja.addEventListener('init', () => {
  initGallery(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initGallery(event.detail.snippet);
});