import naja from "naja";
import History from "adaptive-ui/src/js/utils/history";

class VariantSelector {

    constructor ($variantSelector) {
        const _this = this;

        $variantSelector.find('.js-variant-selector-item').each(function () {
            $(this).on('click', (event) => _this.changeVariant(event));
        });
    }

    changeVariant (event) {
        event.preventDefault();

        let $variant = $(event.currentTarget);
        let url = $variant.attr('href');

        naja.makeRequest('POST', url).then(() => {
            History.pushReloadState(url);
        });
    }

    static init ($container) {
        $container.find('.js-variant-selector').each(function () {
            new VariantSelector($(this));
        });
    }

}

naja.addEventListener('init', (event) => {
    VariantSelector.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    VariantSelector.init($(event.detail.snippet));
});