export default class History {
  static pushReloadState = function (url, title, stateObject = {
    refreshOnPopstate: true
  }) {
      var currentState = history.state || {};

      if (!currentState.hasOwnProperty('refreshOnPopstate') || currentState.refreshOnPopstate !== true) {
          currentState.refreshOnPopstate = true;
          history.replaceState(currentState, document.title, location.href);
      }

      title = title === undefined;

      history.pushState(stateObject, title, url);
  };
}