const dpdOverlayHtmlId = 'js-dpd-place-selector-overlay';
const dpdIframeWrapHtmlId = 'js-dpd-place-selector-iframe-wrap';
const dpdIframeHtmlId = 'js-dpd-place-selector-iframe';
const dpdInputId = 'js-dpd-place-selector-place-id';
const dpdResultId = 'js-dpd-place-selector-result';

class DpdPlaceSelector {

    constructor ($element) {
        const _this = this;

        var $radio = $element.find('input[type="radio"]');
        $('input[type="radio"][name="transportAndPayment[transport]"]').on('change', function () {
            if ($(this).is(':checked') === true && $(this).data('id') != $radio.data('id')) {
                let result = document.getElementById(dpdResultId);
                result.innerText = result.dataset.emptyText;
            }
        });

        $element.on('click', function () {
            _this.createIframe($element);
        });
    };

    createIframe ($element) {
        let parameters = '';

        if ($element.data('country')) {
            parameters += 'enabledCountries=' + $element.data('country');
        }

        let iframeSrc = 'https://api.dpd.cz/widget/latest/index.html?' + parameters;

        let iframeHtml = '<div id="' + dpdOverlayHtmlId + '">' +
            '<div id="' + dpdIframeWrapHtmlId + '">' +
            '<iframe id="' + dpdIframeHtmlId + '" src="' + iframeSrc + '" allow="geolocation">' +
            '</div>' +
            '</div>';

        var bodyNode = document.querySelector('body');
        var iframeNode = document.createElement('div');

        iframeNode.innerHTML = iframeHtml;

        bodyNode.insertBefore(iframeNode, bodyNode.firstChild);

        this.addStylesToOverlay();
        this.addStylesToIframeWrap();
        this.addStylesToIframe();

        window.addEventListener("message", (event) => {
            if(event.data.dpdWidget && event.data.dpdWidget.message === "widgetClose") {
                this.removeIframe();
                return;
            }

            if(event.data.dpdWidget) {
                document.getElementById(dpdInputId).value = event.data.dpdWidget.id;
                document.getElementById(dpdResultId).innerText = event.data.dpdWidget.pickupPointResult.split(", ").slice(1).join(", ");

                this.removeIframe();
            }
        }, false);
    };

    removeIframe () {
        const widget = document.getElementById(dpdOverlayHtmlId);
        if (widget) {
            widget.remove();
        }
    };

    addStylesToOverlay () {
        var overlay = document.getElementById(dpdOverlayHtmlId);

        var styles = {
            'width': '100%',
            'height': '100%',
            'background': 'rgba(0, 0, 0, 0.6)',
            'position': 'fixed',
            'z-index': '99999'
        };

        this.applyStyles(overlay, styles);
    }

    addStylesToIframeWrap () {
        var iframeWrap = document.getElementById(dpdIframeWrapHtmlId);

        var styles = {
            'border': 'none',
            'width': '100%',
            'height': '100%',
            'max-width': '1000px',
            'position': 'fixed',
            'z-index': '999999',
            'left': '50%',
            'top': '50%',
            'transform': 'translate(-50%, -50%)',
            'background': 'white',
        };

        this.applyStyles(iframeWrap, styles);
    }

    addStylesToIframe () {
        var iframe = document.getElementById(dpdIframeHtmlId);

        var styles = {
            'border': 'none',
            'width': '100%',
            'height': '100%'
        };

        this.applyStyles(iframe, styles);
    }

    applyStyles (node, styles) {
        var keys = Object.keys(styles);

        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];

            node.style[key] = styles[key];
        }
    }

    static init($container) {
        $container.find('#js-dpd-place-selector').each(function () {
            new DpdPlaceSelector($(this));
        })
    }
}

naja.addEventListener('init', (event) => {
    DpdPlaceSelector.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    DpdPlaceSelector.init($(event.detail.snippet));
});
