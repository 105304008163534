const defaults = {
    title: '',
    body: '',
    buttonClose: true,
    buttonCancel: false,
    buttonContinue: false,
    textContinue: 'Ano',
    textCancel: 'Ne',
    urlContinue: '#',
    wide: false,
    cssClass: '',
    closeOnBgClick: true,
    eventClose: function () {
    },
    eventContinue: function () {
    },
    eventCancel: function () {
    }
};

const modalInstances = [];

export default class Modal {

    constructor (inputOptions) {
        this.$activeModal = null;

        this.options = { ...defaults, ...inputOptions };

        if (this.$activeModal !== null) {
            this.$activeModal.trigger('modalFastClose');
        }

        modalInstances.forEach(function (el) {
            //console.log('active instance');
           // console.log(el);
            $(el).trigger('modalFastClose');
        })

        this.$modal = $('<div class="modal-dialog" role="dialog" style="pointer-events: all"></div>');

        if (this.options.cssClass !== '') {
            this.$modal.addClass(this.options.cssClass);
        }

        if (this.options.wide) {
            this.$modal.addClass('modal-lg');
        }

        this.$activeModal = this.$modal;
        modalInstances.push(this.$modal);

        this.$modal.bind('modalClose', function () {
            Modal.hideOverlay();
            Modal.hideMainContainer();

            $('body').css('overflow', '');

            $(this).fadeOut(2000, function () {
                $(this).trigger('modalFastClose');
            });
        });

        const _this = this;

        this.$modal.bind('modalFastClose', function () {
            $(this).remove();
            _this.$activeModal = null;
        });

        const $modalContent = $('<div class="modal-content"></div>');
        const $modalHeader = $('<div class="modal-header"></div>');

        if (this.options.title) {
            const $modalTitle = $('<h3 class="modal-title">' + this.options.title + '</h3>');

            $modalHeader.append($modalTitle);
        }

        if (this.options.buttonClose) {
            const $modalCloseButton = $('<button type="button" data-dismiss="modal" class="close"><span aria-hidden="true">×</span></button>');
            $modalCloseButton
                .bind('click.modal', this.options.eventClose)
                .bind('click.modalClose', function () {
                    _this.$modal.trigger('modalClose');

                    return false;
                });

            $modalHeader.append($modalCloseButton);
        }

        if ($modalHeader.children().length > 0) {
            $modalContent.append($modalHeader);
        }

        const $modalBody = $('<div class="modal-body js-modal-content"></div>').html(this.options.body);

        $modalContent.append($modalBody);

        var $modalFooter = $('<div class="modal-footer"></div>');
        if (this.options.buttonCancel) {
            var $modalButtonCancel = $('<a href="javascript:void(0);" class="btn btn-danger"></a>');

            $modalButtonCancel
                .html('<i class="svg svg-close"></i>' + this.options.textCancel)
                .bind('click.modalEventCancel', this.options.eventCancel)
                .bind('click.modalEventClose', this.options.eventClose)
                .bind('click.modalClose', function () {
                    _this.$modal.trigger('modalClose');
                    return false;
                });

            $modalFooter.append($modalButtonCancel);
        }

        if (this.options.buttonCancel) {
            var $modalButtonContinue = $('<a href="javascript:void(0);" class="btn btn-success"></a>');

            $modalButtonContinue
                .text(this.options.textContinue)
                .attr('href', this.options.urlContinue)
                .bind('click.modal', this.options.eventContinue)
                .bind('click.modalContinue', function () {
                    _this.$modal.trigger('modalClose');
                    if ($(this).attr('href') === '#') {
                        return false;
                    }
                });
            $modalFooter.append($modalButtonContinue);
        }

        if ($modalFooter.children().length !== 0) {
            $modalContent.append($modalFooter);
        }

        this.$modal.append($modalContent);

        this.$modal.find('.js-modal-close').each(function () {
            $(this).click(function () {
                _this.$modal.trigger('modalClose');
                return false;
            });
        })

        $('body').keyup(function (event) {
            if (event.keyCode === 27) { // escape
                _this.$modal.trigger('modalClose');
                return false;
            }
        });

        this.show();
    }

    show() {
        Modal.showOverlay();
        Modal.showMainContainer();

        const _this = this;

        if (this.options.closeOnBgClick) {
            Modal.getOverlay().click(function () {
                _this.$activeModal.trigger('modalClose');
                return false;
            });
        }

        $('body').css('overflow', 'hidden');

        this.$modal.hide().appendTo(Modal.getMainContainer());

        const windowAndViewportRatioLimitToCenter = 0.9;
        if (this.$modal.height() / $(window).height() < windowAndViewportRatioLimitToCenter) {
           // console.log(this.$modal);
            this.moveToCenter();
        } else {
            // remove css attribute "top" which is used by function moveToCenter()
            this.$modal.css({ top: '' });
        }

        this.$modal.fadeIn('fast');
    }

    moveToCenter () {
        let relativeY = $(window).height() / 2 - this.$modal.height() / 2;
        let minRelativeY = 10;

        if (relativeY < minRelativeY) {
            relativeY = minRelativeY;
        }

        const top = Math.round(relativeY);

        this.$modal.css({ top: top + 'px' });
    }

    getModal() {
        return this.$modal;
    }

    static getMainContainer() {
        var $mainContainer = $('#modal-main-container');

        if ($mainContainer.length === 0) {
            $mainContainer = $('<div id="modal-main-container" class="modal" style="display: block;"></div>');

            $('body').append($mainContainer);
        }

        return $mainContainer;
    };

    static showMainContainer() {
        Modal.getMainContainer().show();
    };

    static hideMainContainer() {
        Modal.getMainContainer().hide();
    };

    static getOverlay() {
        let $overlay = $('#js-overlay');

        if ($overlay.length === 0) {
            $overlay = $('<div id="js-overlay" class="modal-backdrop show"></div>');
        }

        return $overlay;
    };

    static showOverlay() {
        var $overlay = Modal.getOverlay();
        $('body').append($overlay);
    };

    static hideOverlay() {
        if ($('#js-overlay').length !== 0) {
            $('#js-overlay').remove();
        }
    };

}
