import Modal from '../utils/Modal';

export default class Registration {

    static init() {
        $('.js-registration-button').each(function () {
            $(this).on('click', Registration.onClick)
        });
    };

    static onClick(event) {
        naja.makeRequest(
            'GET',
            $(event.currentTarget).attr('href'),
            null,
            {
                history: false
            }
        ).then(function (response) {
            const modal = new Modal({
                title: 'Registrace',
                body: response.content,
                wide: true,
                cssClass: 'js-registration-modal'
            });

            const $modal = modal.getModal();
            $modal.on('submit', '.js-registration-form', Registration.onSubmit);

            naja.uiHandler.bindUI($modal.get(0));
        });

        event.preventDefault();
    };

    static onSubmit(event) {
        event.preventDefault();

        naja.uiHandler.submitForm(event.target, {
            history: false,
            forceRedirect: true,
            loaderElement: '.js-registration-modal'
        });
    };

}
