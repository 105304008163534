import $ from 'jquery';
import registerFilterAllNodes from "./registerFilterAllNodes";

export default function registerJquery () {
    window.jQuery = $;
    window.$ = $;

    registerFilterAllNodes();
}

registerJquery();
