const initMap = () => {
    const mapElement = document.getElementById('map');

    if (mapElement) {
        // Contact - Google Map
        const mapCenter = { lat: 50.76908375037056, lng: 15.05852682697376 };
        const map = new google.maps.Map(mapElement, {
            // zoom: window.outerWidth > 1000 ? 9.5 : 8.5,
            zoom: 16,
            center: mapCenter,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
        });

        const observer = new IntersectionObserver(entries => {

            entries.forEach(entry => {
                const intersecting = entry.isIntersecting
                if(intersecting){
                    setTimeout(drop,350);
                    observer.unobserve(entry.target);
                }
            })
        })

        observer.observe(document.getElementById("map"))

        const drop = () => {

            const locations = [
                {
                    lat: 50.76908375037056,
                    lng: 15.05852682697376,
                    name: 'Mia Coffee s.r.o',
                    address: 'Nám. Dr. E. Beneše 6/7, Liberec',
                },
            ]

            const markerContent = (title, address) => {
                return `
            <div map-tooltip>
              <h3 class="like-h4">${title}</h3>
              <p>${address}</p>
            <div
        `
            }

            locations.forEach((location) => {

                const marker = new google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng}, map,
                    animation: google.maps.Animation.DROP
                });

                const infowindow = new google.maps.InfoWindow({
                    content: markerContent(location.name, location.address, location.weekdaysOpening, location.weekendOpening),
                });

                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
            })
        }

        const pinIcon =
            `data:image/svg+xml;charset=UTF-8,` +
            encodeURIComponent(
                `<svg width="53" height="53" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                  <path d="M26.1.81a26.15 26.15 0 0 1 26.09 26.1A26.15 26.15 0 0 1 26.09 53 26.15 26.15 0 0 1 0 26.9 26.14 26.14 0 0 1 26.1.82" fill="#083C21"/>
                  <path d="M26.46 8c6.88-.01 5.35 8.25 1.68 15.92a3.64 3.64 0 0 0-3.4 0C21 16.32 19.59 8.02 26.46 8m16.62 9.6c3.45 5.96-4.48 8.77-12.96 9.42a3.68 3.68 0 0 0-1.7-2.94c4.73-7.04 11.21-12.42 14.66-6.48m-.01 19.2c-3.43 5.96-9.83.5-14.63-6.52a3.68 3.68 0 0 0 1.68-2.94c8.47.57 16.38 3.5 12.95 9.46m-16.62 9.59c-6.88 0-5.36-8.25-1.68-15.92a3.66 3.66 0 0 0 3.39-.03c3.74 7.62 5.16 15.93-1.71 15.95m-16.62-9.6c-3.45-5.95 4.46-8.76 12.93-9.42a3.7 3.7 0 0 0 1.73 2.94c-4.73 7.04-11.21 12.42-14.66 6.47m0-19.2c3.43-5.95 9.82-.5 14.62 6.5a3.69 3.69 0 0 0-1.69 2.97c-8.46-.58-16.35-3.5-12.92-9.46" fill="#FEFEFE"/>
              </g>
          </svg>`
            );

        const pinIconActive =
            `data:image/svg+xml;charset=UTF-8,` +
            encodeURIComponent(
                `<svg width="53" height="53" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                  <path d="M26.1.81a26.15 26.15 0 0 1 26.09 26.1A26.15 26.15 0 0 1 26.09 53 26.15 26.15 0 0 1 0 26.9 26.14 26.14 0 0 1 26.1.82" fill="#74b650"/>
                  <path d="M26.46 8c6.88-.01 5.35 8.25 1.68 15.92a3.64 3.64 0 0 0-3.4 0C21 16.32 19.59 8.02 26.46 8m16.62 9.6c3.45 5.96-4.48 8.77-12.96 9.42a3.68 3.68 0 0 0-1.7-2.94c4.73-7.04 11.21-12.42 14.66-6.48m-.01 19.2c-3.43 5.96-9.83.5-14.63-6.52a3.68 3.68 0 0 0 1.68-2.94c8.47.57 16.38 3.5 12.95 9.46m-16.62 9.59c-6.88 0-5.36-8.25-1.68-15.92a3.66 3.66 0 0 0 3.39-.03c3.74 7.62 5.16 15.93-1.71 15.95m-16.62-9.6c-3.45-5.95 4.46-8.76 12.93-9.42a3.7 3.7 0 0 0 1.73 2.94c-4.73 7.04-11.21 12.42-14.66 6.47m0-19.2c3.43-5.95 9.82-.5 14.62 6.5a3.69 3.69 0 0 0-1.69 2.97c-8.46-.58-16.35-3.5-12.92-9.46" fill="#FEFEFE"/>
              </g>
          </svg>`
            );

        const pinSize = new google.maps.Size(33, 33);

        const branches = [
            ...document.querySelectorAll(
                ".map-info:not(.branches-info) .branches .branch"
            ),
        ].map((branchElement) => {
            const branch = {
                lat: +branchElement.getAttribute("data-lat"),
                lng: +branchElement.getAttribute("data-lng"),
                slug: branchElement.getAttribute("data-slug"),
                title: branchElement.querySelector("h2").textContent,
                element: branchElement,
            };

            const marker = new google.maps.Marker({
                position: { lat: branch.lat, lng: branch.lng },
                map: map,
                title: branch.title,
                icon: {
                    url: pinIcon,
                    scaledSize: pinSize,
                },
                optimized: false,
            });

            branch.open = () => {
                branches.forEach((branch) => {
                    branch.element.classList.remove("is-active");

                    branch.marker.setIcon({
                        url: pinIcon,
                        scaledSize: pinSize,
                    });
                });

                marker.setIcon({
                    url: pinIconActive,
                    scaledSize: pinSize,
                });

                branch.element.classList.add("is-active");

                map.setCenter({ lat: branch.lat, lng: branch.lng });

                let zoom = window.outerWidth > 1000 ? 14 : 15;

                if (branch.slug === "dhaba-nuselska" || branch.slug === "dhaba-cafe") {
                    map.setZoom(17);
                }

                window.history.pushState({}, null, branch.slug);

                if (window.outerWidth > 1000) {
                    map.panBy(200, 0);
                }

                document.querySelector(".contact-page").classList.add("is-open");
                document
                    .querySelector(".contact-page")
                    .classList.remove("is-branches-list-open");
            };

            marker.addListener("click", branch.open);

            branch.marker = marker;

            return branch;
        });

        let originalBranchBySlug = null;
        if (window.ORIGINAL_SLUG) {
            originalBranchBySlug = branches.find(
                (branch) => branch.slug == window.ORIGINAL_SLUG
            );

            if (originalBranchBySlug) {
                originalBranchBySlug.open();
            }
        }
    }
}

window.addEventListener('load', () => {
    initMap();
});
