import Modal from '../utils/Modal';
import naja from "naja";
import CartPreview from "../cart/CartPreview";

export default class AddToCart {

    static ajaxSubmit (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (Nette.validateForm(event.target)) {
            naja.uiHandler.submitForm(event.target)
                .then(AddToCart.onSuccess)
                .catch(AddToCart.onError);
        }
    }

    static onSuccess (response) {
        new Modal({
            body: response.data,
            wide: true,
            title: 'Přidáno do košíku',
            cssClass: 'modal-dialog--success',
        });

        CartPreview.triggerReload();
    }

    static onError (jqXHR) {
        if (jqXHR.status !== 0) {
            new Modal({
                content: 'Operace selhala'
            });
        }
    }

    static init ($container) {
        $container.find('.js-product-add-to-cart-form').each(function () {
            $(this).bind('submit', AddToCart.ajaxSubmit);
        });
    }

}

naja.addEventListener('init', (event) => {
    AddToCart.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    AddToCart.init($(event.detail.snippet));
});