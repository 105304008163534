import Headroom from 'headroom.js';

class Navigation {

    constructor () {
        const _this = this;

        $('.js-menu-responsive-toggle').on('click', function () {
            $('.js-navbar').toggleClass('show');
        });

        $('.js-menu-collapse-control').each(function () {
            $(this).on('click', (event) => _this.onNavigationCollapseControlClick(event));
        });

        $('body').on('click', function () {
            _this.closeNavigation($('.js-menu'));
        });

        var navbar = document.querySelector('.navbar');

        if (navbar === null) {
            return;
        }

        var headroom = new Headroom(navbar, {
            offset: 25,
            tolerance: 0,
            classes: {
                notTop: 'navbar--fixed',
            }
        });

        headroom.init();
    }

    onNavigationCollapseControlClick(event) {
        var $target = $(event.target);

        if ($target.hasClass('collapses') === false) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        var $navigation = $('.js-menu');
        var $navigationCollapseControl = $(event.currentTarget);
        var $subNavigation = $navigationCollapseControl.find('.js-sub-navigation');
        var isOpen = $navigationCollapseControl.hasClass('open');

        this.closeNavigation($navigation);

        if (!isOpen && $subNavigation.length > 0) {
            $subNavigation.show();
        }

        $navigationCollapseControl.toggleClass('open', !isOpen);
    }

    closeNavigation ($navigation) {
        $navigation.find('.open ul').hide();
        $navigation.find('.open').removeClass('open');
    }

}

naja.addEventListener('init', (event) => {
    new Navigation();
});

