export default class OrderTransportAndPayment {

    constructor () {
        this.relations = {};

        const paymentTransportRelations = $('.js-transport-payment-relations');

        if (paymentTransportRelations.length > 0) {
            paymentTransportRelations.data('relations').forEach(item => {
                this.addTransportPaymentRelation(item.paymentId, item.transportId);
            });
        }

        this.showApplePayIfPossible();
    }

    addTransportPaymentRelation(paymentId, transportId) {
        if (this.relations[paymentId] === undefined) {
            this.relations[paymentId] = {};
        }

        this.relations[paymentId][transportId] = true;
    };

    transportPaymentRelationExist(paymentId, transportId) {
        if (this.relations[paymentId] !== undefined) {
            if (this.relations[paymentId][transportId] !== undefined) {
                return this.relations[paymentId][transportId];
            }
        }

        return false;
    };

    updateTransports() {
        const $checkedPayment = $('.js-order-payment-input:checked');
        if ($checkedPayment.length > 0) {
            const checkedPaymentId = $checkedPayment.data('id');
            const _this = this;

            $('.js-order-transport-input').each(function (i, radio) {
                const $radio = $(radio);
                const id = $radio.data('id');

                if (_this.transportPaymentRelationExist(checkedPaymentId, id)) {
                    $radio.prop('disabled', false);
                    $radio.closest('label')
                        .removeClass('box-transport-and-payment-chooser__item--disabled')
                } else {
                    $radio.prop('disabled', true);
                    $radio.closest('label')
                        .addClass('box-transport-and-payment-chooser__item--disabled')
                }
            });
        } else {
            $('.js-order-transport-input').each(function (i, radio) {
                const $radio = $(radio);
                $radio.prop('disabled', false);
                $radio.closest('label')
                    .removeClass('box-transport-and-payment-chooser__item--disabled');
            });
        }

        const $checkedTransport = $('.js-order-transport-input:checked');

        if ($checkedTransport.length > 0) {
            $checkedTransport.closest('label')
                .removeClass('box-transport-and-payment-chooser__item--disabled');
        }
    };

    updatePayments() {
        const $checkedTransport = $('.js-order-transport-input:checked');

        if ($checkedTransport.length > 0) {
            const checkedTransportId = $checkedTransport.data('id');
            const _this = this;

            $('.js-order-payment-input').each(function (i, radio) {
                const $radio = $(radio);
                const id = $radio.data('id');

                if ($radio.hasClass('js-gopay-bank-selection')) {
                    return;
                }

                if (_this.transportPaymentRelationExist(id, checkedTransportId)) {
                    $radio.prop('disabled', false);
                    $radio.closest('label')
                        .removeClass('box-transport-and-payment-chooser__item--disabled')
                } else {
                    $radio.prop('disabled', true);
                    $radio.closest('label')
                        .addClass('box-transport-and-payment-chooser__item--disabled')
                }
            });
        } else {
            $('.js-order-payment-input').each(function (i, radio) {
                const $radio = $(radio);

                $radio.prop('disabled', false);
                $radio.closest('label')
                    .removeClass('box-transport-and-payment-chooser__item--disabled');
            });
        }

        const $checkedPayment = $('.js-order-payment-input:checked');

        if ($checkedPayment.length > 0) {
            $checkedPayment.closest('label')
                .removeClass('box-transport-and-payment-chooser__item--disabled');
        }
    };

    onTransportChanged(event) {
        this.updatePayments();

        naja.makeRequest('POST', $(event.target).data('transport-changed-url'), {
            transportId: $(event.target).val()
        }, { unique: false, history: false });
    };

    onPaymentChanged(event) {
        naja.makeRequest('POST', $(event.target).data('payment-changed-url'), {
            paymentId: $(event.target).val()
        }, { unique: false, history: false });

        this.updateTransports();
        this.showGopayOptionsIfNeeded($(event.currentTarget));
    };

    showGopayOptionsIfNeeded($checkedPayment) {
        var checked = $checkedPayment.prop('checked');
        var checkedId = $checkedPayment.data('id');
        var $gopayListBanks = $('.js-gopay-list-banks');

        if (checked) {
            var isSelectedGoPayBankTransfer = false;

            $('.js-order-payment-input').each (function (i, radio) {
                var $radio = $(radio);
                var id = $radio.data ('id');

                if (id !== checkedId ) {
                    $radio.closest('label.transportAndPayment-chooser__item').removeClass( 'transportAndPayment-chooser__item--active');
                } else if ($radio.hasClass('js-gopay-bank-transfer-input')) {
                    isSelectedGoPayBankTransfer = true;
                }
            });

            if (!isSelectedGoPayBankTransfer) {
                $ ('.js-order-gopay-bank-swift-input:checked').each(function () {
                    $(this).prop('checked', false );
                } );
                $gopayListBanks.slideUp();
            }
            else {
                $gopayListBanks.slideDown();
            }

            //$this.closest( 'label.transportAndPayment-chooser__item' ).addClass('transportAndPayment-chooser__item--active');
        } else {
            //$this.closest('label.transportAndPayment-chooser__item' ).removeClass( 'transportAndPayment-chooser__item--active');
        }
    }

   showApplePayIfPossible () {
        var $applePayChoice = $('#js-apple-pay');

        // apple pay is not available at all
        if ($applePayChoice.length === 0) {
            return;
        }

        // // browser is not supported
        if (!window.ApplePaySession) {
            return;
        }

        // check if apple pay is supported
        if (location.protocol === 'https:' && window.ApplePaySession.canMakePayments() === false) {
            return;
        }

        $applePayChoice.show();
    }



    static init () {
        const $transportInputs = $('.js-order-transport-input');
        const $paymentInputs = $('.js-order-payment-input');
        const orderTransportAndPayment = new OrderTransportAndPayment();

        $transportInputs.change((event) => orderTransportAndPayment.onTransportChanged(event, orderTransportAndPayment));
        $paymentInputs.change((event) => orderTransportAndPayment.onPaymentChanged(event, orderTransportAndPayment));

        orderTransportAndPayment.updateTransports();
        orderTransportAndPayment.updatePayments();
    }

}

naja.addEventListener('init', (event) => {
    OrderTransportAndPayment.init();
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    OrderTransportAndPayment.init();
});

