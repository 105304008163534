import Timeout from "../utils/Timeout";
import CartPreview from "./CartPreview";

export default class CartRecalculator {

    constructor ($container) {
        const _this = this;

        // reload content after delay when clicking +/-
        $container.find('.js-cart-item .js-spinbox-plus, .js-cart-item .js-spinbox-minus').click(
            function (event) {
                _this.reloadWithDelay(1000, _this);
                event.preventDefault();
            }
        );

        // reload content after delay after leaving input or pressing ENTER
        // but only if value was changed
        $container.find('.js-cart-item .js-spinbox-input')
            .change(function () {
                $(this).blur(function () {
                    _this.reloadWithDelay(1000, _this);
                });
            })
            .keydown(function (event) {
                if (event.keyCode === KeyCodes.ENTER) {
                    _this.reloadWithDelay(0, _this);
                    event.preventDefault();
                }
            });
    }

    reload () {
        naja.uiHandler.submitForm($('.js-cart-form').get(0)).then(() => CartPreview.triggerReload());
    }

    reloadWithDelay (delay, cartRecalculator) {
        Timeout.setTimeoutAndClearPrevious(
            'cartRecalculator',
            function () {
                cartRecalculator.reload();
            },
            delay
        );
    }

    static init ($container) {
        new CartRecalculator($container);
    }

}

naja.addEventListener('init', (event) => {
    CartRecalculator.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CartRecalculator.init($(event.detail.snippet));
});