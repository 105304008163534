class CheckboxSelectAll {

    constructor($checkboxSelectAll) {
        $checkboxSelectAll.on('change', (event) => this.onChange(event));
    }


    onChange(event) {
        let $input = $(event.currentTarget);

        if ($input.is(':checked') === false) {
            return;
        }

        $('input[name="' + $input.data('targetName') + '"]').each(function () {
            $(this).attr('checked', true);
        })
    }

    static init ($container) {
        $container.find('.js-checkbox-select-all').each(function () {
            new CheckboxSelectAll($(this));
        });
    }
}

naja.addEventListener('init', (event) => {
    CheckboxSelectAll.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CheckboxSelectAll.init($(event.detail.snippet));
});

