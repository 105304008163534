export default class CheckboxToggle {

    constructor ($container) {
        const _this = this;
        const $checkboxToggles = $container.find('.js-checkbox-toggle');

        $checkboxToggles.on('change', (event) => _this.onChange(event));
        $checkboxToggles.each(function () {
            $(this).trigger('change');
        });
    }

    onChange (event) {
        const $checkboxToggle = $(event.currentTarget);
        const $container = this.findContainer($checkboxToggle);

        let show = $checkboxToggle.is(':checked');
        if ($checkboxToggle.data('checkbox-toggle-inverted')) {
            show = !show;
        }

        if (show) {
            $container.slideDown('fast');
        } else {
            $container.slideUp('fast');
        }
    }

    findContainer ($checkboxToggle) {
        if ($checkboxToggle.data('checkbox-toggle-container-id')) {
            return $('#' + $checkboxToggle.data('checkbox-toggle-container-id'));
        }

        return $('.' + $checkboxToggle.data('checkbox-toggle-container-class'));
    }

    static init ($container) {
        new CheckboxToggle($container);
    }

}

naja.addEventListener('init', (event) => {
    CheckboxToggle.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CheckboxToggle.init($(event.detail.snippet));
});
