import 'jquery-hoverintent/jquery.hoverIntent';

export default class CartPreview {

    static reload (event) {
        event.preventDefault();

        naja.makeRequest(
            'GET',
            $(event.currentTarget).data('reload-url'),
            null,
            {
                history: false,
                loaderElement: '#js-cart-preview'
            }
        );
    }

    static init ($container) {
        $container.find('#js-cart-preview').on('reload', CartPreview.reload);
    }

    static triggerReload () {
        $('#js-cart-preview').trigger('reload');
    }

}

naja.addEventListener('init', (event) => {
    CartPreview.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CartPreview.init($(event.detail.snippet));
});