import '../libs/noframework.wypoints';

// Funkce pro inicializaci waypoint animations
const waypointAnimInit = () => {
    const handleIsInviewClass = function(direction, el) {
        if (direction === 'up' && el.classList.contains('is-inview')) {
            el.classList.remove('is-inview');
        } else {
            el.classList.add('is-inview');
        }
    };

    const animationElements = document.querySelectorAll('[data-animation]');
    if (animationElements) {
        const defaultOffset = '95%';

        [...animationElements].forEach((el) => {
            el.classList.add('is-enabled');
            const offset = el.getAttribute('offset') || defaultOffset;
            const waypointLetter = new Waypoint({
                element: el,
                handler(direction) {
                    handleIsInviewClass(direction, this.element);
                },
                offset,
            });
        });

        let resizeTimer;
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                // Run code here, resizing has "stopped"
                if (animationElements.length > 0) {
                    Waypoint.refreshAll();
                }
            }, 250);
        });
    }
};

window.addEventListener('load', () => {
    setTimeout(() => {
        waypointAnimInit();
    }, 200);
});
