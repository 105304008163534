import naja from "naja";

class GoTo {
    static init () {
        $('.js-go-to').each(function () {
            $(this).click(function (e) {
                e.preventDefault();
                var $target = $($(this).attr('href'));

                $("html, body").animate({ scrollTop: $target.offset().top - 160 }, 1000);
            })
        });
    }
}

naja.addEventListener('init', (event) => {
    GoTo.init($('body'));
});


naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    GoTo.init($(event.detail.snippet));
});
