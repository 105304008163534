class PromoCode {

    static init () {
        var $promoCodeSubmitButton = $('#js-promo-code-submit-button');
        var $promoCodeInput = $('#js-promo-code-input');

        $promoCodeSubmitButton.click(function (event) {
            event.preventDefault();
            var code = $promoCodeInput.val();

            if (code !== '') {
                naja.makeRequest('POST', $(this).data('apply-url'), { 'promoCode': code }, { history: false })
                    .then((response) => {
                        if (response.result === false) {
                            alert('Zadaný kód není správný.');
                            $promoCodeInput.val('');
                        }
                    });
            } else {
                alert('Prosím vyplňte promo kód');
            }
        });
    };

}

naja.addEventListener('init', (event) => {
    PromoCode.init($('body'));
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    PromoCode.init($(event.detail.snippet));
});
